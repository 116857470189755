import React from "react";
import { useTranslation } from 'next-i18next';
import { useInView } from "react-intersection-observer";
import OneBubble from "components/svgs/one-bubble";
import { ParagraphText, TextHeadingTwo, Uppercase } from "styles/typography";
import { JumbotronWrapper } from "./jumbotron.styles";

const Jumbotron = () => {
   const { t } = useTranslation('common');
   const { ref, inView } = useInView();
   return (
      <JumbotronWrapper ref={ref}>
         <OneBubble class={inView} />
         <Uppercase center>{t('jumbotron-title')}</Uppercase>
         <TextHeadingTwo center={true} mw="670">
            {t('jumbotron-subtitle')}
         </TextHeadingTwo>
         <ParagraphText center={true} mw="600">
            {t('jumbotron-description')}
         </ParagraphText>
      </JumbotronWrapper>
   );
};

export { Jumbotron };
