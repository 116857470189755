import styled from "styled-components";

export const BubbleWrapper = styled.div`
   position: absolute;
   top: 20%;
   left: 0;
   z-index: 5;
`;

export const OneBubbleWrapper = styled.div`
   position: absolute;
   top: 0;
   left: 0;
   right: 10%;
   z-index: -1;
`;
