import React from "react";
import Image from "next/image";
import { ParagraphText, TextHeadingThree } from "styles/typography/index";
import { FeatureCard, FeaturesWrapper } from "./features.styles";
import { FeaturesList } from "./features.utils";

const Features = () => {
   const featuresList = FeaturesList();
   return (
      <FeaturesWrapper>
         {featuresList.map((item) => (
            <FeatureCard key={item.id}>
               <Image width={90} height={122} src={item.icon} alt={item.description} />
               <TextHeadingThree>{item.title}</TextHeadingThree>
               <ParagraphText>{item.description}</ParagraphText>
            </FeatureCard>
         ))}
      </FeaturesWrapper>
   );
};

export { Features };
