import styled from "styled-components";
import { responsive } from "theme/responsive";

export const MainBannerWrapper = styled.div`
   min-height: 100vh;
   display: grid;
   grid-template-columns: repeat(2, 1fr);
   gap: 2em;
   align-items: center;

   ${responsive("$medium")`
   display: flex;
   align-items: center;
   flex-direction: column;
`}

   & div:nth-child(odd) {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 2em;
   }
`;
