import styled from "styled-components";

export const JumbotronWrapper = styled.div`
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   gap: 1em;
   padding: 3em 0;
   position: relative;
`;
