import React, { useState } from "react";
import { useTranslation } from 'next-i18next';
import { useInView } from "react-intersection-observer";
import { Button } from "components/button";
import VideoPlayer from "components/video-player";
import { ParagraphText, Span, TextHeadingTwo } from "styles/typography";
import { VideoBox, VideoWrappeer } from "./video.styles";

const Video = () => {
   const { t } = useTranslation('common');
   const { ref, inView } = useInView();
   const [showPlayer, setShowPlayer] = useState(false);
   const SRC = "https://www.youtube.com/embed/vSnzsx1nOp8?controls=0";
   // const SRC = "https://www.youtube.com/embed/9alGqDIXkWI?autoplay=1";

   return (
      <VideoWrappeer ref={ref}>
         <VideoBox
            url="/images/0000.png"
            onClick={() => setShowPlayer(true)}
            inView={inView}
         ></VideoBox>

         <div style={{ marginTop: "5em" }}>
            <TextHeadingTwo>
               {t('video-title')}
               <Span color="#7211d4"> Metricks </Span>
            </TextHeadingTwo>
            <ParagraphText mw="1000" style={{ marginTop: "1.5em", marginBottom: "1em" }}>
               {t('video-description', { Metricks: 'Metricks' })}
            </ParagraphText>

            <ParagraphText>
               {t('video-paragraph')}
            </ParagraphText>
            <Button
               variant="primary"
               size="regular"
               style={{ marginTop: "3em" }}
               onClick={() => setShowPlayer(true)}
            >
               {t('video-cta-text')}
            </Button>
         </div>

         <VideoPlayer show={showPlayer} src={SRC} onClose={() => setShowPlayer(false)} />
      </VideoWrappeer>
   );
};

export { Video };
