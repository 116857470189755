import styled from "styled-components";
import { responsive } from "theme/responsive";

export const BigCtaWrapper = styled.section`
   position: relative;
   background: #7211d4;
   border-radius: 66px;
   padding: 3em;
   max-width:1056.18px;
   width:100%;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   gap: 3em; 
   margin:0 auto;
   margin-top: 3em;
   margin-bottom: 3em;

   ${responsive("$small")`
   padding: 2em;
   margin: 2em 0;
   border-radius: 33px;

`}
`;

export const CtaGroup = styled.div`
   display: flex;
   gap: 2em;

   ${responsive("$small")`
   flex-direction: column;
   gap: 0;

   button {
      margin-bottom: 1.5em;
   }
`}
`;

export const DecorLinePos1 = styled.div`
   position: absolute;
   top: 0;
   z-index: -1;
   right: 0;

   ${responsive("$small")`
   display: none;
   `}
`;

export const DecorLinePos2 = styled.div`
   position: absolute;
   bottom: 0;
   left: 0;

   ${responsive("$small")`
   display: none;
   `}
`;
