import React from "react";
import { useTranslation } from 'next-i18next';
import { useInView } from "react-intersection-observer";
import { Quote } from "assets/svgs";
import { Uppercase, TextHeadingTwo, Span, ParagraphText } from "styles/typography";
import { IntroWrapper } from "styles/wrappers";
import { TestimonialCard, TestimonialsWrapper, TestimonialsCards } from "./testimonial.styles";
import { TestimonialsList } from "./testimonials.utils";

const Testimonials = () => {
   const { t } = useTranslation('common');
   const { ref, inView } = useInView();
   const testimonialsList = TestimonialsList();
   return (
      <TestimonialsWrapper>
         <IntroWrapper>
            <Uppercase>{t('testimonials-title')}</Uppercase>
            <TextHeadingTwo center={true} mw="417">
               {t('testimonials-subtitle')} <Span color="#7211d4">Metricks.</Span>
            </TextHeadingTwo>
         </IntroWrapper>

         <TestimonialsCards ref={ref}>
            {testimonialsList.map((item) => (
               <TestimonialCard key={item.id} className={inView ? "scale-up-card-animation" : ""}>
                  {/* <div className="img">
                     <Image src={item.image} alt={item.name} width={50} height={40} />
                  </div> */}
                  <ParagraphText>{item.testimony}</ParagraphText>
                  <div>
                     <h3>{item.name}</h3>
                     <small>{item.position}</small>
                  </div>

                  <Quote />
               </TestimonialCard>
            ))}
         </TestimonialsCards>
      </TestimonialsWrapper>
   );
};

export { Testimonials };
