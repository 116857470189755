import React from "react";
import { useTranslation } from 'next-i18next';
import { CTADecorLine, CTADecorLineFlip, TalkToHumanIcon } from "assets/vectors";
import { Button } from "components/button";
import useHelper from "helpers";
import { TextHeadingTwo } from "styles/typography";
import { BigCtaWrapper, CtaGroup, DecorLinePos1, DecorLinePos2 } from "./big-cta.styles";

declare global {
   interface Window {
      $crisp: any;
   }
}

const BigCta = () => {
   const { t } = useTranslation('common');
   const { handleCta } = useHelper();

   {
      new Date().getFullYear();
   }

   const handleClick = () => {
      window.$crisp.push(['do', 'chat:open']);
      window.$crisp.push(["set", "message:text", ["Hi! I'd like to get help."]]);
      // window.fcWidget.show();
   };

   return (
      <BigCtaWrapper>
         <TextHeadingTwo mw="579" center={true} style={{ color: "#fff" }}>
            {t('cta-title')}
         </TextHeadingTwo>

         <CtaGroup>
            <Button
               onClick={handleCta}
               variant="primary"
               size="regular"
               style={{
                  backgroundColor: "#fff",
                  color: "#721dd4",
               }}
            >
               {t('try-cta-text')}
            </Button>

            <Button variant="outline" size="regular" onClick={() => handleClick()}>
               <TalkToHumanIcon /> <span>{t('cta-support-text')}</span>
            </Button>
         </CtaGroup>
         <DecorLinePos1>
            <CTADecorLine />
         </DecorLinePos1>
         <DecorLinePos2>
            <CTADecorLineFlip />
         </DecorLinePos2>
      </BigCtaWrapper>
   );
};

export { BigCta };
