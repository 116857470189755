import styled from "styled-components";
import {
   arrowBounce,
   slideInAnimation,
   slideUpLeftAnimation,
   slideDownLeftAnimation,
   slideDownRightAnimation,
   slideUpRightAnimation,
   openTextAnimation,
   iconShakeAnimation,
   slideInLeftAnimation,
   scaleUpAnimation,
   scaleUpCardAnimation,
} from "styles/animations";
import { responsive } from "theme/responsive";

export const ContainerWrapper = styled.main`
   padding: 0 6em;

   ${responsive("$small")`
   padding: 0 1.5em;
   `}

   .bounce-right-anim {
      animation-name: ${arrowBounce};
      animation-duration: 5s;
      animation-timinig-function: cubic-bezier(0.55, 0, 1, 0.45);
      animation-iteration-count: infinite;
   }

   .slide-entrance-anim {
      animation-name: ${slideInAnimation};
      animation-duration: 1.5s;
      animation-timinig-function: cubic-bezier(0.32, 0, 0.67, 0);
      animation-iteration-count: 1;
   }
   .slide-entrance-left-anim {
      animation-name: ${slideInLeftAnimation};
      animation-duration: 1.5s;
      animation-timinig-function: cubic-bezier(0.32, 0, 0.67, 0);
      animation-iteration-count: 1;
   }

   .slide-up-left-anim {
      animation-name: ${slideUpLeftAnimation};
      animation-duration: 1.5s;
      animation-timinig-function: cubic-bezier(0.32, 0, 0.67, 0);
      animation-iteration-count: 1;
   }
   .slide-up-right-anim {
      animation-name: ${slideUpRightAnimation};
      animation-duration: 1.5s;
      animation-timinig-function: cubic-bezier(0.32, 0, 0.67, 0);
      animation-iteration-count: 1;
   }
   .slide-down-left-anim {
      animation-name: ${slideDownLeftAnimation};
      animation-duration: 1.5s;
      animation-timinig-function: cubic-bezier(0.32, 0, 0.67, 0);
      animation-iteration-count: 1;
   }

   .slide-down-right-anim {
      animation-name: ${slideDownRightAnimation};
      animation-duration: 1.5s;
      animation-timinig-function: cubic-bezier(0.32, 0, 0.67, 0);
      animation-iteration-count: 1;
   }
   .open-text-animation {
      animation-name: ${openTextAnimation};
      animation-duration: 1.5s;
      animation-timinig-function: cubic-bezier(0.68, -0.6, 0.32, 1.6);
      animation-iteration-count: 1;
   }
   .icon-shake-animation {
      animation-name: ${iconShakeAnimation};
      animation-duration: 1s;
      animation-timinig-function: ease-in-out;
      animation-iteration-count: 1;
   }
   .scale-up-animation {
      animation-name: ${scaleUpAnimation};
      animation-duration: 1s;
      animation-timinig-function: ease-in-out;
      animation-iteration-count: 1;
   }

   .scale-up-card-animation {
      animation-name: ${scaleUpCardAnimation};
      animation-duration: 1.5s;
      animation-timinig-function: cubic-bezier(0.68, -0.6, 0.32, 1.6);
      animation-iteration-count: 1;
   }
`;
