import React from "react";
import { OneBubbleWrapper } from "./svg.styles";

type BubbleProps = {
   class: boolean;
};
const OneBubble = (props: BubbleProps) => {
   return (
      <OneBubbleWrapper>
         <svg
            width="60"
            height="60"
            viewBox="0 0 60 60"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
         >
            <circle
               cx="29.6938"
               cy="30.1968"
               r="29.3284"
               transform="rotate(-16.739 29.6938 30.1968)"
               fill="url(#paint0_linear_1197_211)"
               className={props.class ? "slide-up-left-anim" : ""}
            />
            <defs>
               <linearGradient
                  id="paint0_linear_1197_211"
                  x1="9.4853"
                  y1="0.868393"
                  x2="33.8605"
                  y2="45.2954"
                  gradientUnits="userSpaceOnUse"
               >
                  <stop stopColor="#7211D4" />
                  <stop offset="1" stopColor="white" stopOpacity="0" />
               </linearGradient>
            </defs>
         </svg>
      </OneBubbleWrapper>
   );
};

export default OneBubble;
