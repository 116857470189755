export const Quote = () => (
   <svg
      style={{
         position: "absolute",
         bottom: 20,
         right: 20,
      }}
      width="52"
      height="45"
      viewBox="0 0 52 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
   >
      <path
         opacity="0.1"
         fillRule="evenodd"
         clipRule="evenodd"
         d="M29.6379 22.3218V0.012207H51.7484V22.3218C51.7484 34.6241 41.8304 44.6315 29.6379 44.6315V37.1949C37.7656 37.1949 44.3782 30.5227 44.3782 22.3218H29.6379ZM14.8978 22.3228H0.157471V0.0131836H22.268V22.3228C22.268 34.6251 12.3499 44.6325 0.157471 44.6325V37.1959C8.28511 37.1959 14.8978 30.5237 14.8978 22.3228Z"
         fill="#606C84"
      />
   </svg>
);

export const Star = () => (
   <svg width="58" height="60" viewBox="0 0 58 60" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
         d="M29.0612 0.152344L36.8724 21.81L57.9818 29.824L36.8724 37.8381L29.0612 59.4957L21.25 37.8381L0.140625 29.824L21.25 21.81L29.0612 0.152344Z"
         fill="white"
      />
      <path
         d="M52.2669 13.6191L53.3941 16.7443L56.4401 17.9007L53.3941 19.0571L52.2669 22.1823L51.1398 19.0571L48.0938 17.9007L51.1398 16.7443L52.2669 13.6191Z"
         fill="#F5C765"
      />
      <path
         d="M52.2669 13.6191L53.3941 16.7443L56.4401 17.9007L53.3941 19.0571L52.2669 22.1823L51.1398 19.0571L48.0938 17.9007L51.1398 16.7443L52.2669 13.6191Z"
         fill="#F5C765"
      />
      <path
         d="M7.63713 2.60742L9.37383 7.4227L14.0672 9.20452L9.37383 10.9863L7.63713 15.8016L5.90042 10.9863L1.20703 9.20452L5.90042 7.4227L7.63713 2.60742Z"
         fill="#F5C765"
      />
      <path
         d="M7.63713 2.60742L9.37383 7.4227L14.0672 9.20452L9.37383 10.9863L7.63713 15.8016L5.90042 10.9863L1.20703 9.20452L5.90042 7.4227L7.63713 2.60742Z"
         fill="#F5C765"
      />
   </svg>
);

export const BackArrow = () => (
   <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
         fillRule="evenodd"
         clipRule="evenodd"
         d="M7.57169 0.352829C7.66231 0.443216 7.7342 0.550593 7.78325 0.668808C7.83231 0.787023 7.85756 0.913754 7.85756 1.04174C7.85756 1.16973 7.83231 1.29646 7.78325 1.41468C7.7342 1.53289 7.66231 1.64027 7.57169 1.73065L2.42125 6.87943L7.57225 12.0288C7.66272 12.1192 7.73448 12.2266 7.78344 12.3448C7.8324 12.463 7.8576 12.5897 7.8576 12.7177C7.8576 12.8456 7.8324 12.9723 7.78344 13.0905C7.73448 13.2087 7.66272 13.3161 7.57225 13.4066C7.48178 13.4971 7.37437 13.5688 7.25617 13.6178C7.13797 13.6667 7.01128 13.6919 6.88333 13.6919C6.75539 13.6919 6.6287 13.6667 6.5105 13.6178C6.39229 13.5688 6.28489 13.4971 6.19442 13.4066L0.35618 7.56834C0.265564 7.47795 0.19367 7.37058 0.144616 7.25236C0.0955624 7.13415 0.0703125 7.00741 0.0703125 6.87943C0.0703125 6.75144 0.0955624 6.62471 0.144616 6.50649C0.19367 6.38828 0.265564 6.2809 0.35618 6.19051L6.19442 0.352274C6.28481 0.261658 6.39218 0.189764 6.5104 0.14071C6.62861 0.0916564 6.75534 0.0664062 6.88333 0.0664062C7.01132 0.0664062 7.13805 0.0916564 7.25627 0.14071C7.37448 0.189764 7.48186 0.261658 7.57225 0.352274L7.57169 0.352829Z"
         fill="black"
      />
   </svg>
);
