import React from "react";
import { useTranslation } from "next-i18next";
import Image from "next/image";
import product_hunt from "assets/icons/banner/product_hunt.png";
import medium from "assets/icons/banner/medium.png";
import ranking from "assets/icons/banner/ranking.png";
import quora from "assets/icons/banner/quora.png";
import combinator from "assets/icons/banner/combinator.png";
import BannerImage from "assets/images/home/01.png";
import { ArrowLeft, Star } from "assets/vectors";
import { Button } from "components/button";
import useHelper from "helpers";
import { TextHeadingOne, ParagraphText, Span } from "styles/typography";
import { MainBannerWrapper } from "./banner.styles";

const MainBanner = () => {
   const { t } = useTranslation("common");
   const { handleCta } = useHelper();

   return (
      <MainBannerWrapper>
         <div>
            <TextHeadingOne>
               {t("banner-title")} <br />
               {t("banner-brk")}{" "}
               <Span color="#7211d4" className="banner-keyword" data-text="Affiliate Program">
                  {t("banner-subtitle")}
               </Span>
            </TextHeadingOne>
            <ParagraphText>{t("banner-description")}</ParagraphText>

            {/* <Button
               variant="primary"
               id="getStarted"
               size="regular"
               width="190"
               onClick={handleCta}
            >
               Start Now, Free
            </Button> */}
            <Button variant="primary" size="regular" width="190" onClick={handleCta}>
               {/* {t("banner-cta-text")} <ArrowLeft /> */}
               Start Now <ArrowLeft />
            </Button>
            {/* <div ></div> */}

            <ParagraphText color="#000000" style={{ display: "flex", gap: "0.5em" }}>
               <Star /> {t("banner-paragraph")}
            </ParagraphText>

            <ParagraphText style={{ fontWeight: 600, lineHeight: 0 }}>Find us on</ParagraphText>

            <div style={{ display: "flex", alignItems: "center", gap: "1.5em" }}>
               <Image src={medium} alt="medium" className="slide-entrance-anim" />
               <Image src={ranking} alt="shopify" className="slide-entrance-anim" />
               <Image src={quora} alt="wix" className="slide-entrance-anim" />
               <Image src={product_hunt} alt="magento" className="slide-entrance-anim" />
               <Image src={combinator} alt="woo commerce" className="slide-entrance-anim" />

               {/* <Image src={paypal} alt="paypal" className="slide-entrance-anim" />
               <Image src={wix} alt="wix" className="slide-entrance-anim" />
               <Image src={magento} alt="magento" className="slide-entrance-anim" />
               <Image src={shopify} alt="shopify" className="slide-entrance-anim" />
               <Image src={woocommerce} alt="woo commerce" className="slide-entrance-anim" /> */}
            </div>
         </div>

         <div className="slide-entrance-anim">
            <Image src={BannerImage} alt="Banner Image" />
         </div>
      </MainBannerWrapper>
   );
};

export { MainBanner };
