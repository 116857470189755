import { useTranslation } from 'next-i18next';
import Icon1 from "assets/icons/1.png";
import Icon2 from "assets/icons/2.png";
import Icon3 from "assets/icons/3.png";

export const FeaturesList = () => {
   const { t } = useTranslation('common');
   return [
      {
         id: 1,
         icon: Icon1,
         title: t('feature-list-icon1-title'),
         description:
            t('feature-list-icon1-description'),
      },
      {
         id: 2,
         icon: Icon2,
         title: t('feature-list-icon2-title'),
         description:
            t('feature-list-icon2-description'),
      },
      {
         id: 3,
         icon: Icon3,
         title: t('feature-list-icon3-title'),
         description:
            t('feature-list-icon3-description'),
      },
   ];
} 
