import { useTranslation } from 'next-i18next';
import Icon1 from "assets/images/testimonial-1.png";
import Icon2 from "assets/images/testimonial-2.png";
import Icon3 from "assets/images/testimonial-3.png";

export const TestimonialsList = () => {
   const { t } = useTranslation('common');
   return [
      {
         id: 1,
         image: Icon1,
         name: t('testimonials-list-icon1-title'),
         testimony:
            t('testimonials-list-icon1-description'),
         position: t('testimonials-list-icon1-position'),
      },
   
      {
         id: 2,
         image: Icon2,
         name: t('testimonials-list-icon2-title'),
         testimony:
            t('testimonials-list-icon2-description', { Metricks: 'Metricks' }),
         position: t('testimonials-list-icon2-position'),
      },
   
      {
         id: 3,
         image: Icon3,
         name: t('testimonials-list-icon3-title'),
         testimony:
            t('testimonials-list-icon3-description'),
         position: t('testimonials-list-icon3-position'),
      },
   ];
}
