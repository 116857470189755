import styled, { css } from "styled-components";
import { responsive } from "theme/responsive";

export const TextHeadingOne = styled.h1<{ mw?: string; center?: boolean; color?: string }>`
   color: ${({ theme, color }) => color ?? theme.colors?.black};
   max-width: ${({ mw }) => mw ?? 625}px;
   font-weight: 800;
   font-size: 40px;
   letter-spacing: 2px;
   line-height: 1.5;
   ${({ center }) =>
      center &&
      css`
         text-align: center;
      `};

   ${responsive("$small")`
      max-width: 100%
   `}

   text-transform: capitalize;
`;

export const TextHeadingTwo = styled.h1<{ mw?: string; center?: boolean; color?: string }>`
   color: ${({ theme, color }) => color ?? theme.colors?.black};
   max-width: ${({ mw }) => mw ?? 625}px;
   font-size: 1.75rem;
   font-weight: ${({ theme }) => theme.fontWeight?.bold};
   line-height: 1.5;
   ${({ center }) =>
      center &&
      css`
         text-align: center;
      `};

   ${responsive("$small")`
      max-width: 100%
   `}

   &::first-letter {
      text-transform: capitalize;
   }
`;

export const TextHeadingThree = styled.h1<{ mw?: string; center?: boolean }>`
   color: ${({ theme }) => theme.colors?.black};
   max-width: ${({ mw }) => mw ?? 625}px;
   font-size: 1.25rem;
   cursor: pointer;
   font-weight: ${({ theme }) => theme.fontWeight?.bold};
   line-height: 2;
   ${({ center }) =>
      center &&
      css`
         text-align: center;
      `};

   ${responsive("$small")`
      max-width: 100%
   `}

   &::first-letter {
      text-transform: capitalize;
   }
`;

export const Text24 = styled.h1<{ mw?: string; center?: boolean }>`
   color: ${({ theme }) => theme.colors?.black};
   max-width: ${({ mw }) => mw ?? 625}px;
   font-size: 1.5rem;
   font-weight: ${({ theme }) => theme.fontWeight?.bold};
   line-height: 2;
   text-transform: capitalize;
   ${({ center }) =>
      center &&
      css`
         text-align: center;
      `};

   ${responsive("$small")`
      max-width: 100%
   `}
`;

export const TextBrief = styled.p`
   color: ${({ theme }) => theme.colors?.secondary};
   max-width: 800px;
   font-size: 1.2rem;
   letter-spacing: 1px;

   ${responsive("$small")`
   max-width: 100%
`}
`;

export const ParagraphText = styled.p<{
   mw?: string;
   bold?: boolean;
   color?: string;
   center?: boolean;
}>`
   font-size: 14px;
   line-height: 2.5;
   font-weight: ${({ bold, theme }) =>
      !bold ? theme.fontWeight?.regular : theme.fontWeight?.semiBold};
   max-width: ${({ mw }) => mw ?? 550}px;
   color: ${({ color, theme }) => color ?? theme.colors?.secondary};
   ${({ center }) =>
      center &&
      css`
         text-align: center;
      `};

   a {
      color: ${({ theme }) => theme.colors?.primary};
      text-decoration: none;
   }

   img {
      max-width: 85%;
   }

   ${responsive("$small")`
      max-width: 100%;
   
      img {
         max-width: 95%;
      }
   `}
`;

export const Span = styled.span<{ color: string }>`
   color: ${({ color }) => color};

   ${responsive("$small")`
   max-width: 100%
`}
`;

export const Underlined = styled.h2<{ color?: string; mw?: string }>`
   font-size: 2rem;
   color: ${({ color, theme }) => color ?? theme.colors?.primary};
   font-weight: ${({ theme }) => theme.fontWeight?.bold};
   border-bottom: solid 3px ${({ color, theme }) => color ?? theme.colors?.primary};
   line-height: 2;
   margin: 1em 0;
   max-width: ${({ mw }) => mw ?? 300}px;

   ${responsive("$small")`
   max-width: 100%
`}
`;

export const Uppercase = styled.p<{ mw?: string; fs?: string; color?: string; center?: boolean }>`
   max-width: ${({ mw }) => mw ?? 360}px;
   font-size: ${({ fs }) => fs ?? 14}px;
   font-weight: 600;
   color: ${({ color, theme }) => color ?? theme.colors?.main};
   text-transform: uppercase;
   ${({ center }) =>
      center &&
      css`
         text-align: center;
      `};

   ${responsive("$small")`
      max-width: 100%
   `}
`;

export const RichText = styled.p`
   & > * {
      text-overflow: ellipsis;
      overflow-x: hidden;
      max-width: 500px;
      white-space: nowrap;
   }
`;

export const Small = styled.p<{ mw?: number }>`
   font-size: 18px;
   color: ${({ theme, color }) => color ?? theme.colors.grey02};
   font-weight: regular;
   line-height: 2;
   max-width: ${({ mw }) => mw}px;
`;

export const ActionText = styled.div`
   text-align: center;
   color: ${({ theme }) => theme.colors.primary};
   font-weight: 500;
   cursor: pointer;
   padding: 5px 10px;
`;
