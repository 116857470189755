import React, { FC } from "react";
import { ContainerWrapper } from "./main-container.styles";

export interface Props {
   children: React.ReactNode;
}

const MainContainer: FC<Props> = ({ children }) => {
   return <ContainerWrapper>{children}</ContainerWrapper>;
};

export { MainContainer };
