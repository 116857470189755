/* eslint-disable jsx-a11y/iframe-has-title */
import Image from "next/image";
import Modal from "react-modal";
import closeIcon from "assets/icons/cancel.svg";
import styles from "./player.module.scss";

Modal.setAppElement("#modal-root");

export default function VideoPlayer({ show = false, onClose, src }) {
   const customStyles = {
      overlay: {
         position: "fixed",
         top: 0,
         left: 0,
         right: 0,
         bottom: 0,
         zIndex: 200,
      },
      content: {
         border: "none",
         top: 0,
         left: 0,
         right: 0,
         bottom: 0,
         borderRadius: 0,
      },
   };

   return (
      <Modal style={customStyles} isOpen={show} onRequestClose={onClose} preventScroll>
         <div className={styles.playerContainer}>
            <iframe
               src={src}
               className={styles.iframe}
               frameBorder="0"
               allow="autoplay; fullscreen; picture-in-picture"
               allowFullScreen
            ></iframe>
            {/* <iframe
               width="420"
               height="315"
               src="https://www.youtube.com/embed/tgbNymZ7vqY"
            ></iframe> */}
            <div className="w-full h-16 flex items-center place-content-end">
               <button onClick={onClose} className={styles.closeIcon} title="close">
                  <Image src={closeIcon} alt="close-button" />
               </button>
            </div>
         </div>
      </Modal>
   );
}
