import styled from "styled-components";
import { responsive } from "theme/responsive";

export const IntroWrapper = styled.div`
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   gap: 1em;
   padding: 3em 0;
   margin-top: 3em;
`;

export const IconWrapper = styled.span`
   border-radius: 50%;
   width: 50px;
   height: 50px;
   display: flex;
   align-items: center;
   justify-content: center;
   background: linear-gradient(180deg, #e6e6e6 0%, #ffffff 100%);
   box-shadow: 2.67535e-16px 4.36918px 8.73835px rgba(51, 51, 51, 0.135),
      inset -6.53974e-17px -1.06802px 1.06802px #dfdfdf,
      inset 6.53974e-17px 1.06802px 1.06802px #ffffff;
`;

export const CategoryTagWrapper = styled.div`
   display: flex;
   justify-content: center;
   gap: 1.5em;
   padding: 2.5em 0;

   ${responsive("$medium")`
   flex-direction: column;
   justify-content: center;
   align-items: center;
   `}

   button {
      padding: 1em 1.5em;
      border: solid 1px #7211d4;
      color: #7211d4;
      border-radius: 25px;
   }
`;
