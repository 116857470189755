import styled, { css } from "styled-components";
import { slideInLeftAnimation } from "styles/animations";
import { responsive } from "theme/responsive";

export const VideoWrappeer = styled.div`
   display: grid;
   grid-template-columns: repeat(2, 1fr);
   margin: 7em 0;
   gap: 2em;

   ${responsive("$small")`
   grid-template-columns: repeat(1, 1fr);
`}
`;

export const VideoBox = styled.div<{ url: string; inView: boolean }>`
   width: 100%;
   height: 600px;
   background-image: url(${({ url }) => url});
   background-position: center;
   background-size: cover;
   background-repeat: no-repeat;
   position: relative;
   cursor: pointer;
   ${({ inView }) =>
      inView &&
      css`
         animation-name: ${slideInLeftAnimation};
         animation-duration: 1.5s;
         animation-timinig-function: cubic-bezier(0.32, 0, 0.67, 0);
         animation-iteration-count: 1;
      `}

   & .team {
      position: absolute;
      bottom: -50px;
      right: 0;
   }
`;
