import styled from "styled-components";
import { responsive } from "theme/responsive";

export const FeaturesWrapper = styled.section`
   display: flex;
   justify-content: space-around;
   gap: 2em;
   margin: 2.5em 0;

   ${responsive("$small")`
   flex-direction: column;
   justify-content: center;
   align-items: center;
`}
`;

export const FeatureCard = styled.div`
   display: inline-block;
   width: 300px;
`;
