import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { BigCta } from "components/big-cta";
import { Footer } from "components/footer";
import { NavBar } from "components/navbar";
import { MainBanner } from "containers/banner";
// import { ChoosePlan } from "containers/choose-plan";
// import { Cta } from "containers/cta";
import { Features } from "containers/features";
import { Jumbotron } from "containers/jumbotron";
import { MainContainer } from "containers/main-container";
// import { Solutions } from "containers/solutions";
import { Testimonials } from "containers/testimonials";
import { Video } from "containers/video";
import { Meta } from "seo/meta";

export default function Home() {
   const { t } = useTranslation('common');
   return (
      <Meta
         title={t('seo-home-title', { Metricks: 'Metricks' })}
         description={t('seo-home-description', { Metricks: 'Metricks' })}
      >
         <MainContainer>
            <NavBar />
            <MainBanner />
            <Jumbotron />
            <Features />
            <Video />
            {/* <Solutions /> */}
         </MainContainer>
         {/* <Cta /> */}
         {/* <ChoosePlan /> */}
         <MainContainer>
            <Testimonials />
            <BigCta />
         </MainContainer>
         <Footer />
      </Meta>
   );
}

export async function getStaticProps({ locale }) {
   return {
     props: {
       ...(await serverSideTranslations(locale, ['common', 'footer'])),
       // Will be passed to the page component as props
     },
   };
 }
