import styled from "styled-components";
import { responsive } from "theme/responsive";

export const TestimonialsWrapper = styled.section`
   padding: 3em 0;

   ${responsive("$small")`
   padding: 2em 0;
`}
`;

export const TestimonialsCards = styled.div`
   display: flex;
   align-items: flex-start;
   justify-content: space-between;
   gap: 2em;

   ${responsive("$small")`
   flex-direction: column;
   justify-content: center;
   align-items: center;
   gap: 3em;
`}
`;

export const TestimonialCard = styled.div`
   position: relative;
   padding: 2em 1.5em;
   max-width: 376.48px;
   background: #ffffff;
   box-shadow: 0px 20px 48px rgba(0, 0, 0, 0.06);
   border-radius: 24px;

   .img {
      position: absolute;
      top: -45px;
      right: -25px;
   }

   div {
      margin-top: 1em;
   }

   small {
      color: ${({ theme }) => theme.colors?.grey02};
   }
`;
